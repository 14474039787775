.deal-list__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}
.deal-card__wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.deal-card__priority{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    opacity: 0.5;
    text-align: center;
    padding: 2px;
    font-size: 12px;
}
.deal-card__info{
    display: flex;
    flex-direction: column;
}
.deal-card__footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.deal-card__avatar{
    overflow: hidden;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}