.partner-users__wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.partner-users__header{

}
.partner-users__title{

}
.partner-users__items{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.partner-users__item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.partner-users__item_info{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}
.partner-users__item_name{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.partner-users__item_role{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.partner-users__item_buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex: 3;
}
.partner-users__item_buttons button{
    width: 250px;
    height: 35px;
}
.partner-users__buttons{

}
.button-add-user-extension{
    width: 250px;
    height: 35px;
}