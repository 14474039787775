.associated-entities__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.associated-entities__header{
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.associated-entities__body{
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}